import { Column } from 'exceljs';
import { useState, useEffect } from 'react';
import { getLearningGamePlayerLeaderboard } from '../../../common/api/apicall';
import { months } from '../../../common/constants';
import Leaderboard from '../../../common/leaderboard';

import { IDatableColumn } from '../../../common/leaderboard/column.interface';
import { ILearningGamePlayerRank } from '../../../interface/learning-game-player-rank.interface';

const divisionId = '66729b11bf63cb07c97c839d';
const includeNonPostive = true;
function AsianPaintsDei() {
  const [data, setData] = useState<ILearningGamePlayerRank[]>([]);
  const columns: IDatableColumn[] = [
    {
      name: 'Employee Id',
      key: 'employeeid',
    },
    {
      name: 'Name',
      key: 'name',
    },
    {
      name: 'Highest Score',
      key: 'highestScore',
      type: 'number',
    },
    {
      name: 'Date',
      key: 'completedAt',
      type: 'string',
      formatter: '',
    },
  ];

  const worksheetColumns = [
    { header: 'Employee Id', key: 'employeeid', width: 50 } as Column,
    { header: 'Name', key: 'name', width: 50 } as Column,
    {
      header: 'Highest Score',
      key: 'highestScore',
      width: 15,
      alignment: { horizontal: 'center' },
    } as Column,
    { header: 'Date', key: 'completedAt', width: 15 } as Column,
  ];
  // Employee Id	Name	Highest Score	Completed Date

  useEffect(() => {
    getLearningGamePlayerLeaderboard(divisionId, { includeNonPostive , gameOver:true})
      .then((res: any[]) => {
        const filteredPlayers: any[] = [];
        res.forEach((player) => {
          player['name'] =
            (player['firstName'] || '') +
            ' ' +
            (player['lastName'] || '') +
            (player['lastname'] || '');

          // 12 Sep, 2022
          if (player.highestScore) {
            player['highestScore'] = player['highestScore'];
          }

          if (player.extraDetail) {
            player['employeeid'] = player.extraDetail['employeeid'];
          }

          if (player['completedAt']) {
            const date = new Date(player['completedAt']);
            player['completedAt'] = `${date.getDate()} ${
              months[date.getMonth()]
            }, ${date.getFullYear()}`;
          }
          if (player.extraDetail) {
            filteredPlayers.push(player);
          }
        });
        setData(filteredPlayers);
      })
      .catch((error) => console.log('error: ', error));
  }, []);

  return (
    <Leaderboard
      data={data}
      columns={columns}
      showIndex={true}
      type="new"
      total={data.length}
      name="Asian Paints"
      // headerCellStyle={classes.tableHeaderCell}
      downloadable={true}
      worksheetColumns={worksheetColumns}
    />
  );
}

export default AsianPaintsDei