import {
  Card,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { IDatableColumn } from '../column.interface';
import { IDatatableProps } from '../datatable-props.interface';

const useStyles = makeStyles({
  tableHeaderCell: {
    fontWeight: 'bold',
    // fontSize: '2rem',
    // height: '4rem',
    // borderBottom: '2px solid black',
    color: 'white',
    background: '#171e3a',
  },
  tableBody: {
    background: '#f5f7fa',
    // color: '#f5f7fa',
  },
  tableCell: {
    padding: '8px',
  },
});

export default function NewStyleLeaderboardTable(
  props: IDatatableProps,
): JSX.Element {
  const data = props.data;
  const columns = props.columns;
  const classes = useStyles();

  const showIndex = 'showIndex' in props ? props.showIndex : false;
  const headerCellStyle = props.headerCellStyle || classes.tableHeaderCell;

  function extractValue(value: any, keystring: string) {
    const keys = keystring.split('.');
    for (const key of keys) {
      value = value[key];
      if (!value) {
        break;
      }
    }
    return value;
  }

  return (
    <Card>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {showIndex && (
                <TableCell align="center" className={headerCellStyle} key="#">
                  #
                </TableCell>
              )}
              {columns.map((column: IDatableColumn) => (
                <TableCell
                  align="center"
                  className={headerCellStyle}
                  key={column.key}
                >
                  {column.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody className={classes.tableBody}>
            {data.map((row: any, index: number) => (
              <TableRow key={index}>
                {showIndex && (
                  <TableCell align="center" key={index}>
                    {index + 1}
                  </TableCell>
                )}
                {columns.map((column: IDatableColumn, i: number) => (
                  <TableCell
                    align="center"
                    key={i}
                    className={classes.tableCell}
                  >
                    {column.key && column.keyType === 'deepkey'
                      ? extractValue(row, column.key)
                      : row[column.key]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}
