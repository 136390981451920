import { Container, makeStyles } from '@material-ui/core';
import { IDatatableProps } from '../datatable-props.interface';
import NewStyleLeaderboardHeader from './new-style-leaderboard-header';
import NewStyleLeaderboardTable from './new-style-leaderboard-table';

const useStyles = makeStyles({
  containerWidth: { maxWidth: '100%', padding: 0 },
});

export default function NewStyleLeaderboard(props: IDatatableProps) {
  const classes = useStyles();  
  return (
    <Container className={classes.containerWidth}>
      <NewStyleLeaderboardHeader
        total={props.total}
        name={props.name}
        downloadable={props.downloadable}
        data={props.data}
        worksheetColumns={props.worksheetColumns}
      />
      <NewStyleLeaderboardTable
        data={props.data}
        columns={props.columns}
        showIndex={props.showIndex}
        headerCellStyle={props.headerCellStyle}
      />
    </Container>
  );
}
