import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faPeopleLine } from '@fortawesome/free-solid-svg-icons';

import {
  AppBar,
  Badge,
  Box,
  Button,
  makeStyles,
  Toolbar,
} from '@material-ui/core';
import { INewDatatableHeaderProps } from './new-datatable-header-props.interface';
import { Column, Workbook } from 'exceljs';
import * as fs from 'file-saver';

const useStyles = makeStyles({
  header: {
    backgroundColor: '#FFFEFE',
    color: '#000',
  },
  logo: {
    fontFamily: 'Work Sans, sans-serif',
    fontWeight: 600,
    // color: '#FFFEFE',
    textAlign: 'left',
  },
  tools: {
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  downloadTxt: {
    margin: '5px',
  },
  totalBadge: {
    marginLeft: '2vw',
  },
});

function downloadReport(
  name: string,
  data: any[],
  worksheetColumns: Column[],
): void {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet(name);
  worksheet.columns = worksheetColumns;
  worksheet.addRows(data);
  workbook.xlsx.writeBuffer().then((data) => {
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    fs.saveAs(blob, name + '.xlsx');
  });
}

export default function NewStyleLeaderboardHeader(
  props: INewDatatableHeaderProps,
) {
  const name = props.name || '';
  const total = props.total;
  const downloadable = props.downloadable;
  const data = props.data || [];
  const worksheetColumns = props.worksheetColumns || [];
  const classes = useStyles();
  return (
    <Box>
      <AppBar position="static">
        <Toolbar className={classes.header}>
          <div>
            <strong>{name}</strong>

            <Badge
              badgeContent={total}
              className={classes.totalBadge}
              color="primary"
              max={1000000}
              overlap="rectangular"
            >
              <FontAwesomeIcon
                icon={faPeopleLine}
                color="primary"
              ></FontAwesomeIcon>
            </Badge>
          </div>
          {downloadable && (
            <div className={classes.tools}>
              <Button
                color="inherit"
                onClick={() => downloadReport(name, data, worksheetColumns)}
              >
                <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                <span className={classes.downloadTxt}>Download</span>
              </Button>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
