import { Tabs, Tab, Box } from '@material-ui/core';
import { useState } from 'react';
import { ITabPanelProps } from '../../interface/tab-panel.interface';
import Participants from './Participants';
import Analytics from './Analytics';


// const useStyles = makeStyles({
// });

function TabPanel(props: ITabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default  function GenieStaging() {
  const [value, setValue] = useState(0);

  const handleChange = (event: any, newValue: number) => {
    console.log('event: ', event, 'newValue: ', newValue);

    setValue(newValue);
  };

  // const classes = useStyles();

  return (
    <>
      <Tabs
        value={value}
        variant="scrollable"
        scrollButtons="auto"
        onChange={(event, i) => handleChange(event, i)}
        aria-label="scrollable auto tabs example"
      >
        <Tab
          label="Participants"
          style={{ fontSize: 13 }}
          {...a11yProps(0)}
        />
        <Tab
          label="Analytics"
          style={{ fontSize: 13 }}
          {...a11yProps(1)}
        />
       
      </Tabs>
      <TabPanel value={value} index={0}>
       <Participants/>
      </TabPanel>
      <TabPanel value={value} index={1}>
       <Analytics/>
      </TabPanel>

  
    </>
  );
}

