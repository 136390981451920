import { Column } from 'exceljs';
import { useState, useEffect } from 'react';
import { ILearningGamePlayerRank } from '../../interface/learning-game-player-rank.interface';
import { IDatableColumn } from '../../common/leaderboard/column.interface';
import { getLearningGamePlayerLeaderboard } from '../../common/api/apicall';
import Leaderboard from '../../common/leaderboard';
import { months } from '../../common/constants';


const divisionId = '6660560547e6335b77efe517';
export default function HomeFirstAppraisal2024() {
  const [data, setData] = useState<ILearningGamePlayerRank[]>([]);
  const columns: IDatableColumn[] = [

    {
      name: 'Name',
      key: 'name',
    },
    {
        name: 'Email ID',
        key: 'email',
        type: 'string',
      },
      {
        name: 'Employee Code',
        key: 'employeeId',
      },
    {
      name: 'Date of Completion',
      key: 'completedAt',
      type: 'string',
      formatter: '',
    },
  ];

  const worksheetColumns = [

    { header: 'Name', key: 'name', width: 50 } as Column,
    { header: 'Email ID', key: 'email', width: 50 } as Column,
    { header: 'Employee Code', key: 'employeeId', width: 30 } as Column,
    { header: 'Date of Completion', key: 'completedAt', width: 30 } as Column,
  ];

  useEffect(() => {
    getLearningGamePlayerLeaderboard(divisionId, {
      scorePosition: 'last',
      includeNonPostive: true,
      gameOver: true,
    })
      .then((res: any[]) => {
        const filteredPlayers: any[] = [];
        res.forEach((player) => {
          player['name'] =
            (player['firstName'] || '') +
            ' ' +
            (player['lastName'] || '') +
            (player['lastname'] || '');


          player.email = player.email;
          if (player.extraDetail) {
            player['employeeId'] =
              player.extraDetail['employeeid'] ||
              player.extraDetail['employeeId'];
          }

          if (player['completedAt']) {
            const date = new Date(player['completedAt']);
            player['completedAt'] = `${date.getDate()} ${
              months[date.getMonth()]
            }, ${date.getFullYear()}`;
          }
          if (player.extraDetail) {
            filteredPlayers.push(player);
          }
        });
        setData(filteredPlayers);
      })
      .catch((error) => console.log('error: ', error));
  }, []);

  return (
    <Leaderboard
      data={data.sort(
        (a, b) =>
          new Date(b.completedAt).getTime() - new Date(a.completedAt).getTime(),
      )}
      columns={columns}
      showIndex
      type="new"
      total={data.length}
      name="HomeFirst - APPRAISAL 2024"
      downloadable={true}
      worksheetColumns={worksheetColumns}
    />
  );
}

