import { Column } from 'exceljs';
import { useState, useEffect } from 'react';
import { ILearningGamePlayerRank } from '../../interface/learning-game-player-rank.interface';
import { IDatableColumn } from '../../common/leaderboard/column.interface';
import Leaderboard from '../../common/leaderboard';
import { months } from '../../common/constants';
import {
  fetchJourney,
  getGenieLearningGamePlayerLeaderboard,
} from '../../common/api/apicall';
import { useParams } from 'react-router-dom';

const includeNonPostive = true;
export default  function Participants() {
  const [data, setData] = useState<ILearningGamePlayerRank[]>([]);
  const { id: journeyId } = useParams();
  const [journeyDetails, setJourneyDetails] = useState<{
    name: string;
    divisionId: string;
  }>();

  const columns: IDatableColumn[] = [
    {
      name: 'Learner Id',
      key: 'learnerId',
    },
    {
      name: 'Name',
      key: 'name',
    },
    {
      name: 'Email',
      key: 'email',
    },
    {
      name: 'Highest Score',
      key: 'highestScore',
      type: 'number',
    },
    {
      name: 'Date',
      key: 'completedAt',
      type: 'string',
      formatter: '',
    },
  ];

  const worksheetColumns = [
    { header: 'Learner Id', key: 'learnerId', width: 50 } as Column,
    { header: 'Name', key: 'name', width: 50 } as Column,
    { header: 'Email', key: 'email', width: 50 } as Column,
    {
      header: 'Highest Score',
      key: 'highestScore',
      width: 15,
      alignment: { horizontal: 'center' },
    } as Column,
    { header: 'Date', key: 'completedAt', width: 15 } as Column,
  ];

  useEffect(() => {
    async function getGenieJourney() {
      try {
        const journey = await fetchJourney(journeyId as string,false);
        setJourneyDetails(journey);
        getGenieLearningGamePlayerLeaderboard(journey.divisionId,false, {
          includeNonPostive,
        })
          .then((res: any[]) => {
            const filteredPlayers: any[] = [];
            res.forEach((player) => {
              player['name'] =
                (player['firstName'] || '') +
                ' ' +
                (player['lastName'] || '') +
                (player['lastname'] || '');

              // 12 Sep, 2022
              if (player.highestScore) {
                player['highestScore'] = player['highestScore'];
              }

              if (player.extraDetail) {
                player['employeeid'] = player.extraDetail['employeeid'];
              }

              if (player['completedAt']) {
                const date = new Date(player['completedAt']);
                player['completedAt'] = `${date.getDate()} ${
                  months[date.getMonth()]
                }, ${date.getFullYear()}`;
              }
              if (player.extraDetail) {
                filteredPlayers.push(player);
              }
            });
            setData(filteredPlayers);
          })
          .catch((error) => console.log('error: ', error));

        console.log({ journey });
      } catch (error) {}
    }
    getGenieJourney();
  }, []);

  return (
    <Leaderboard
      data={data}
      columns={columns}
      showIndex={true}
      type="new"
      total={data.length}
      name={journeyDetails?.name}
      // headerCellStyle={classes.tableHeaderCell}
      downloadable={true}
      worksheetColumns={worksheetColumns}
    />
  );
}

