import { ILeaderboardProps } from './leaderboard-props.interface';
import NewStyleLeaderboard from './new-style-leaderboard';
import OldStyleLeaderboardTable from './old-style-leaderboard/old-style-leaderboard-table';

function Leaderboard(props: ILeaderboardProps) {
  return (
    <>
      {props.type === 'old' ? (
        <OldStyleLeaderboardTable
          data={props.data}
          columns={props.columns}
          showIndex={props.showIndex}
          headerCellStyle={props.headerCellStyle}
        />
      ) : (
        <NewStyleLeaderboard
          data={props.data}
          columns={props.columns}
          showIndex={props.showIndex}
          headerCellStyle={props.headerCellStyle}
          total={props.total}
          name={props.name}
          downloadable={props.downloadable}
          worksheetColumns={props.worksheetColumns}
        />
      )}
    </>
  );
}

export default Leaderboard;
