import { Column } from 'exceljs';
import { useState, useEffect } from 'react';
import { ILearningGamePlayerRank } from '../../interface/learning-game-player-rank.interface';
import { IDatableColumn } from '../../common/leaderboard/column.interface';
import Leaderboard from '../../common/leaderboard';
import { fetchJourney, getAnalytics } from '../../common/api/apicall';
import { useParams } from 'react-router-dom';

const includeNonPostive = true;

export default function Analytics() {
  const [data, setData] = useState<ILearningGamePlayerRank[]>([]);
  const { id: journeyId } = useParams();
  const [journeyDetails, setJourneyDetails] = useState<{
    name: string;
    divisionId: string;
  }>();

  const columns: IDatableColumn[] = [
    {
      name: 'Question no',
      key: 'number',
    },
    {
      name: 'Question text',
      key: 'question',
      type: 'string',
    },
    {
      name: 'Accuracy',
      key: 'accuracy',
    },
  ];

  const worksheetColumns = [
    { header: 'Question no', key: 'number', width: 50 } as Column,
    { header: 'Question text', key: 'question', width: 50 } as Column,
    { header: 'Accuracy', key: 'accuracy', width: 50 } as Column,
  ];

  useEffect(() => {
    async function getGenieJourney() {
      try {
        const journey = await fetchJourney(journeyId as string, false);
        setJourneyDetails(journey);
        const analytics = await getAnalytics(journey.divisionId, false);

        // Process the analytics data
        const processedData = analytics.map((question: { correctOnFirstAttempt: number; totalPlayers: number; questionText: any; }, index: number) => {
          const accuracy = ((question.correctOnFirstAttempt / question.totalPlayers) * 100).toFixed(2);
          
          return {
            number: index + 1,
            question: question.questionText,
            accuracy: `${accuracy}%`,
          };
        });

        setData(processedData);
      } catch (error) {
        console.log('Error fetching data:', error);
      }
    }

    getGenieJourney();
  }, [journeyId]);

  return (
    <Leaderboard
      data={data}
      columns={columns}
      showIndex={false}
      type="new"
      total={data.length}
      name={journeyDetails?.name}
      downloadable={true}
      worksheetColumns={worksheetColumns}
    />
  );
}
