import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { ILearningGamePlayerRank } from '../../interface/learning-game-player-rank.interface';
import { IListParams } from '../../interface/list-param.interface';
import { IPlayerCompletedResponse } from '../../interface/player-completed-response.interface';
import { IValuesGamePlayerRank } from '../../interface/values-game-player-rank.interface';

const baseURL = process.env.REACT_APP_ADMINV2_API_URL;
const genieKey = process.env.REACT_APP_GENIE_KEY;
const genieProdBaseUrl = process.env.REACT_APP_ADMINV2_API_URL;

const genieStagingBaseUrl = process.env.REACT_APP_STAGING_API_URL;

export function getLearningGamePlayerLeaderboard(
  divisionId: string,
  params?: ILearningGamePlayerLeaderboardQuery,
): Promise<ILearningGamePlayerRank[]> {
  let queryParams: ILearningGamePlayerLeaderboardQuery = { divisionId };

  if (params) {
    queryParams = {
      maxRank: params.maxRank || 10000,
      divisionId,
      includeNonPostive: params.includeNonPostive,
      gameOver: params.gameOver,
      scorePosition: params.scorePosition,
    };
  }

  const query: AxiosRequestConfig = { params: queryParams };
  return axios
    .get(`${baseURL}/learning-players/leaderboard`, query)
    .then((result: AxiosResponse) => result.data);
}

export function getEdelweisLearningGamePlayerLeaderboard(
  params?: ILearningGamePlayerLeaderboardQuery,
): Promise<ILearningGamePlayerRank[]> {
  return axios
    .post(
      `${baseURL}/learning-players/leaderboard/divisions`,
      {
        divisions: params?.divisionIds,
      },
      {
        params: {
          includeNonPostive: true,
          scorePosition: 'last',
          maxRank: 100000,
        },
      },
    )
    .then((result: AxiosResponse) => result.data);
}

export function getGenieLearningGamePlayerLeaderboard(
  divisionId: string,
  prod: boolean,
  params?: ILearningGamePlayerLeaderboardQuery,
): Promise<ILearningGamePlayerRank[]> {
  let queryParams: ILearningGamePlayerLeaderboardQuery = { divisionId };

  if (params) {
    queryParams = {
      maxRank: params.maxRank || 10000,
      divisionId,
      includeNonPostive: params.includeNonPostive,
      gameOver: params.gameOver,
      scorePosition: params.scorePosition,
    };
  }

  const query: AxiosRequestConfig = { params: queryParams };
  return axios
    .get(
      `${
        prod ? genieProdBaseUrl : genieStagingBaseUrl
      }/learning-players/leaderboard`,
      query,
    )
    .then((result: AxiosResponse) => result.data);
}

export function getLearningGamePlayerLeaderboardByDivision(
  divisionId: string,
): Promise<ILearningGamePlayerRank[]> {
  return axios
    .get(`${baseURL}/learning-players/division/${divisionId}`)
    .then((result: AxiosResponse) => result.data);
}

export async function getValuesGamePlayerLeaderboard(
  divisionId: string,
  valuesGameId: string,
  maxRank?: number,
  scorePosition?: 'first' | 'last',
): Promise<IValuesGamePlayerRank[]> {
  const params: IValuesPlayerLeaderboardQuery = {
    maxRank: maxRank || 10000,
    divisionId,
    scorePosition: scorePosition || 'last',
    valuesGameId,
  };
  const query: AxiosRequestConfig = { params };
  return axios
    .get(`${baseURL}/values-game-player/leaderboard`, query)
    .then((result: AxiosResponse) => result.data);
}

export async function getCompletedPlayerLeaderboard(
  divisionId: string,
  params: IListParams,
): Promise<IPlayerCompletedResponse> {
  const query: AxiosRequestConfig = { params };

  return axios
    .get(`${baseURL}/players/gameStatus/${divisionId}`, query)
    .then((result: AxiosResponse) => result.data);
}

export async function fetchJourney(
  id: string,
  prod: boolean,
): Promise<{ divisionId: string; name: string }> {
  const { data } = await axios.get(
    `${prod ? genieProdBaseUrl : genieStagingBaseUrl}/genie/journey/${id}`,
    {
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'api-key': genieKey,
      },
    },
  );
  return data;
}

export function getAnalytics(divisionId: string, prod: boolean): Promise<any> {
  return axios
    .get(
      `${
        prod ? genieProdBaseUrl : genieStagingBaseUrl
      }/learning-players/attemptAnalytics?divisionId=${divisionId}`,
      {
        headers: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'Api-key': process.env.REACT_APP_API_KEY,
        },
      },
    )
    .then((result: AxiosResponse) => result.data);
}

export interface ILearningGamePlayerLeaderboardQuery {
  limit?: number;
  skip?: number;
  divisions?: string;
  search?: string;
  divisionId?: string;
  maxRank?: number;
  includeNonPostive?: boolean;
  gameOver?: boolean;
  scorePosition?: string;
  nomax?: boolean;
  divisionIds?: string[];
}

export interface IValuesPlayerLeaderboardQuery
  extends ILearningGamePlayerLeaderboardQuery {
  valuesGameId: string;
  scorePosition: string;
}
